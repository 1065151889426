<template>
    <div>
        <div>
            <v-btn
                class="mr-4"
                min-width="0"
                outlined
                text
                small
                @click="$router.go(-1)"
                title="回上一頁"
                value="回上一頁"
                aria-label="回上一頁"
            >
                <v-icon>mdi-arrow-left</v-icon>回上一頁
            </v-btn>
        </div>
        <div class="row no-gutters justify-center mt-4">
            <h2 class="page-title col-auto">評量說明</h2>
        </div>
        <div class="main-content row no-gutters justify-center mt-4" style="max-width: 600px;">
            <v-col cols="12" class="mb-3">
                <div class="d-flex flex-no-wrap">

                    <v-avatar class="ma-3" size="150" tile>
                        <v-img :src="thumbnail" :alt="title"></v-img>
                    </v-avatar>
                    <div>
                        <v-card-title class="text-h6" v-text="title"></v-card-title>

                        <v-card-subtitle v-text="quizHint"></v-card-subtitle>

                        <v-card-actions>
                        <v-btn class="ml-2" width="100%" color="success" title="開始測驗" value="開始測驗" aria-label="開始測驗" @click="goToPersonalData()">
                            開始測驗
                        </v-btn>
                        </v-card-actions>
                    </div>

                </div>
            </v-col>
        </div>
    </div>
</template>

<script>
import { quizServices } from "@/services/quiz.js";

export default {
    name: "QuizInstructions",
    components: {},
    data() {
        return {
            quizId: -1,
            title: '',
            quizHint: '',
            thumbnail: '',
        };
    },
    async created() {

        this.$store.commit("setQuizTitle", '');
       // this.$root.$emit('scaleTitle', '');
        sessionStorage.removeItem('quiz_id');
        sessionStorage.removeItem('user_id');

        let id = this.$route.params.id;
        const quiz = await quizServices.getQuizInstructions(id);
        console.log(quiz.data.id)
        if (!quiz.data.id) {
            this.$router.push("/scale")
        }

        this.quizId = quiz.data.id;
        this.quizHint = quiz.data.hint;
        this.title = quiz.data.title;
        this.thumbnail = quiz.data.thumbnailUrl;
        
        sessionStorage.quiz_id = this.quizId;
        sessionStorage.scale_title = this.title;
    },
    methods: {
        goToPersonalData() {
            this.$router.push('/scale/${id}/personalData'.replace('${id}', this.quizId));
        },
    },
};
</script>

<style scoped>

</style>